import React, { MutableRefObject, useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import humanizeDuration from 'humanize-duration';
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';

import { TooManyRequests, cleanupWs, useWebsocket } from "django-rest-react";
import { nuovo_punteggio_vuoto, useEvento, useLogin, useProblema, usePunteggi } from '../reducers';
import { FormGroupBootstrap, ConfirmedButton } from '../common-components'
import {
  TabellaPunteggi, GraficoPunteggi, MappaLeaflet,
  CountdownClock, ThrottleToast,
  MessaggiImportantiList, IstogrammaPunteggi, IstogrammaPunteggi2,
  TabellaPunteggiMinimal, RispostaFeed, DisplayOrario,
} from '../components';
import { set_tutti_punteggi_action, set_punteggi_squadre_action, set_markov_stato, set_punteggio_action, change_parameters } from '../actions'
import {
  get_tutti_punteggi, parse_classifica,
  refresh_problemi, get_squadre_sessione,
  get_markov_stato, dispatchable_score, get_sessione_by_pk,
  get_evento_by_pk, connectPunteggiWs, connectPunteggiEventiWs
} from '../api';
import {
  Evento, PunteggiElaborati,
  SquadraInSessione,
  TunableParametersForm as TunableParameters
} from '../api/types';
import store from '../store';
import { nice_date, nice_date_discorsiva } from '../utils'
import { semplificato } from '../globals'


const pageMatch = useParams<{ pk: string, scuola_id?: string }>


const extraProps = (
  evento: ReturnType<typeof useEvento>[0],
  allPunteggi: ReturnType<typeof usePunteggi>) => {
  let inizio; let fine;
  if (evento) {
    inizio = new Date(Date.parse(evento.inizio));
    fine = new Date(Date.parse(evento.fine));
  }

  const squadra_punteggi_storia = allPunteggi || nuovo_punteggio_vuoto;
  const punteggi = squadra_punteggi_storia.states[0];
  const punteggi_problemi = punteggi?.punteggi_problemi || [];

  return {
    inizio: inizio,
    fine: fine,
    punteggi: punteggi,
    punteggi_problemi: punteggi_problemi,
  }
}


const classifica_semp = (classifica: PunteggiElaborati["classifica"], scuole_id: number[], squadre: SquadraInSessione[]) => {
  return classifica.filter(squadra => {
    if (squadra.posizione <= 20) return true;
    const whitelist = [50, 100, 150, 200, 300, 400, 500]
    if (whitelist.includes(squadra.posizione)) return true;
    if (scuole_id.includes(squadre.find(sq => sq.id == squadra.id)?.scuola.id)) return true;
    return false;
  })
}


const get_timer = (inizio: Date, fine: Date, stato: StatoEvento) => {
  if (!(inizio && fine)) {
    return null
  }
  let fine_countdown;
  // Non dovrebbe esserci bisogno di settare un countdown
  // per ri-renderizzare quando si passa l'inizio, in quanto
  // render verrà chiamata di nuovo sicuramente con l'autorefresh
  // dei punteggi.
  if (stato == StatoEvento.DeveIniziare) {
    fine_countdown = inizio;
  } else {
    fine_countdown = fine;
  }
  if (stato != StatoEvento.Finito) {
    const nome = stato == StatoEvento.InCorso ? "fine" : "inizio";
    return (
      <div className="d-flex justify-content-center">
        <CountdownClock
          id={`sessione`}
          key={nome}
          fine={fine_countdown}
        />
      </div>);
  }
  return null;
}


const get_testo_blocco = (adesso: Date, inizio: Date, fine: Date) => {
  let testo, blocco_fine;
  if (inizio) {
    if (adesso < inizio) {
      testo = `L'evento non è ancora iniziato. Inizierà ${nice_date_discorsiva(inizio)} e durerà ${humanizeDuration(fine.getTime() - inizio.getTime(), { language: "it" })}.`;
    } else if (adesso > fine) {
      testo = `L'evento si è concluso ${nice_date_discorsiva(fine)} ed è durato ${humanizeDuration(fine.getTime() - inizio.getTime(), { language: "it" })}.`;
      blocco_fine = (
        <h1 className="text-center text-warning py-2">La gara è terminata!</h1>
      )
    } else {
      testo = `L'evento è in corso e finirà ${nice_date_discorsiva(fine)}`;
    }
  }
  return [testo, blocco_fine];
}


const refreshProblemi = (evento: Evento, timeoutSetter: (val: number) => void) => {
  const inizio = new Date(evento.inizio)
  const adesso = new Date();
  if (inizio > adesso) {
    // Set timer che scatta 3 secondi dopo l'inizio
    const timeout = window.setTimeout(() => {
      refreshProblemi(evento, timeoutSetter);
    }, inizio.getTime() - adesso.getTime() + 1000)
    timeoutSetter(timeout);
  } else {
    refresh_problemi(evento.id);
  }
}


enum StatoEvento {
  DeveIniziare,
  InCorso,
  Finito,
}


const getBloccoSlider = (path: string, sessione_pk: number, evento: Evento) => {
  const rex = new RegExp("/sessione/([0-9]+)/punteggi/standard-model/");
  if (!path.match(rex)) return null;
  const FIELDS = [
    "MAX_ERRORI_RAISE_MONTEPREMI", "ERRORE_PUNTEGGI_RAISE", "PTI_TOLTI_ERRORE",
    "PTI_BASE_MONTEPREMI", "BONUS_VELOCITA", "SCALING_BONUS_VELOCITA",
    "SCALING_BONUS_MONTEPREMI",
  ] as (keyof Omit<TunableParameters, "PUNTEGGIO_BASE_PROBLEMI">)[];

  let initial = { PUNTEGGIO_BASE_PROBLEMI: 20 } as TunableParameters;
  if (evento != null) {
    for (const key of FIELDS) {
      const value = evento[key];
      if (typeof value === "number") {
        initial[key] = value
      } else {
        initial[key] = parseFloat(value);
      }
    }
  }

  const onSubmit = (values: TunableParameters, actions: FormikActions<TunableParameters>) => {
    store.dispatch(change_parameters(sessione_pk, values))
    const last = store.getState().punteggi_markov[sessione_pk.toString()]
    store.dispatch(set_punteggio_action(dispatchable_score(last), sessione_pk))
    actions.setSubmitting(false);
  }
  return (
    <div className="card bg-light-primary">
      <div className="card-body">
        <h4 className="card-title">Opzioni sviluppatore</h4>
        <p>Questa sezione permette di sperimentare parametri diversi nel sistema di punteggi. Non modifica in alcun modo i veri risultati della gara.</p>

        <Formik
          onSubmit={onSubmit}
          initialValues={initial}
          enableReinitialize
        >
          {({ handleReset, isSubmitting }) => {
            return (<Form className="needs-validation">
              <FormGroupBootstrap
                name="MAX_ERRORI_RAISE_MONTEPREMI" type="number" step={1}
              />
              <FormGroupBootstrap
                name="ERRORE_PUNTEGGI_RAISE" type="number" step={1}
              />
              <FormGroupBootstrap
                name="PTI_TOLTI_ERRORE" type="number" step={1}
              />
              <FormGroupBootstrap
                name="PTI_BASE_MONTEPREMI" type="number" step={1}
              />
              <FormGroupBootstrap
                name="PUNTEGGIO_BASE_PROBLEMI" type="number" step={1}
              />
              <FormGroupBootstrap
                name="BONUS_VELOCITA" type="number" step={1}
              />
              <FormGroupBootstrap
                name="SCALING_BONUS_VELOCITA" type="number" step={0.001}
              />
              <FormGroupBootstrap
                name="SCALING_BONUS_MONTEPREMI" type="number" step={0.001}
              />
              <div className="d-flex justify-content-end">
                <ConfirmedButton type="warning" onSubmit={handleReset}>Reset</ConfirmedButton>
                <button disabled={isSubmitting} className="btn btn-success" type="submit">Ricalcola</button>
              </div>

            </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}


const loadEventInfos = async (
  pk: number,
  setSessione: ReturnType<typeof useState<any>>[1],
  setEvento: ReturnType<typeof useState<Evento>>[1],
  setSquadre: ReturnType<typeof useState<any>>[1],
  setThrottleError: ReturnType<typeof useState<any>>[1],
  setStatoEvento: ReturnType<typeof useState<StatoEvento>>[1],
  wsPunteggi: MutableRefObject<any>,
  wsEventi: MutableRefObject<any>,
  timeouts: number[]) => {
  const sessione = await get_sessione_by_pk(pk);
  setSessione(sessione);
  document.title = `Punteggi ${sessione.nome} - GaS`;

  const evento = await get_evento_by_pk(sessione.evento);
  setEvento(evento);

  // Prendiamo le squadre
  const squadre = await get_squadre_sessione(pk)
  setSquadre(squadre)

  // Stato dell'evento
  const adesso = new Date();
  const inizio = new Date(evento.inizio);
  const fine = new Date(evento.fine);
  if (adesso > inizio) {
    refresh_problemi(evento.id);
    if (adesso < fine) {
      timeouts.push(window.setTimeout(() => setStatoEvento(StatoEvento.Finito), fine.getTime() - adesso.getTime()))
      setStatoEvento(StatoEvento.InCorso)
    } else {
      setStatoEvento(StatoEvento.Finito)
    }
  } else {
    timeouts.push(window.setTimeout(() => setStatoEvento(StatoEvento.InCorso), inizio.getTime() - adesso.getTime()))
    timeouts.push(window.setTimeout(() => {
      refresh_problemi(evento.id)
    }, inizio.getTime() - adesso.getTime() + 500))
    setStatoEvento(StatoEvento.DeveIniziare)
  }


  const tante = semplificato(squadre.length)
  // Se sono poche, refresh lato backend e tutti i punteggi
  if (!tante) {

    connectPunteggiWs(wsPunteggi.current, pk);
    try {
      const json = await get_tutti_punteggi(pk);
      const nuovo = json.map(item => parse_classifica(item));
      store.dispatch(set_tutti_punteggi_action(nuovo, pk));
    } catch (err) {
      if (err instanceof TooManyRequests) {
        setThrottleError(err)
      } else {
        throw err;
      }
    }
  }

  // In ogni caso sottoscriviamo al feed degli eventi, sia se sono poche che tante
  // ma viene usato per il calcolo punteggi solo se sono poche.
  store.dispatch(set_punteggi_squadre_action(pk, evento, squadre, 40))
  // Irrilevante mettere un solo problema tanto adesso facciamo il fetch dello stato
  // che sa il numero giusto
  get_markov_stato(pk).then(stato => {
    store.dispatch(set_markov_stato(pk, evento, stato))
    const state = store.getState().punteggi_markov[pk]
    if (tante) {
      store.dispatch(set_punteggio_action(dispatchable_score(state), pk))
    }
    connectPunteggiEventiWs(wsEventi.current, pk, !tante);
  })
}


const Punteggi = (props: {}) => {
  const [squadre, setSquadre] = useState([]);
  const [statoEvento, setStatoEvento] = useState(StatoEvento.DeveIniziare);
  const [throttleError, setThrottleError] = useState(null);
  const [evento, setEvento] = useState(null);
  const [sessione, setSessione] = useState(null);
  const params = pageMatch()
  const location = useLocation();
  const navigate = useNavigate();
  const [tabKey, setTabKey] = useState(location.hash.split('#')[1] || "tabella-semplificata");

  const session_pk = parseInt(params.pk);
  const login = useLogin();
  const allPunteggi = usePunteggi(session_pk);
  const dettagli_problemi = useProblema(evento?.id);

  const { inizio, fine, punteggi } = extraProps(evento, allPunteggi);
  const wsPunteggi = useWebsocket();
  const wsEventi = useWebsocket();


  useEffect(() => {
    const timeouts = [] as number[];
    loadEventInfos(
      session_pk, setSessione, setEvento,
      setSquadre, setThrottleError, setStatoEvento,
      wsPunteggi, wsEventi, timeouts).catch(err => console.error("Errore cusumano", err))
    return () => {
      timeouts.forEach(val => {
        if (val) {
          clearTimeout(val)
        }
      })
      cleanupWs(wsPunteggi.current)
      cleanupWs(wsEventi.current)
    }
  }, [])


  const ultimo: PunteggiElaborati = punteggi || {
    ora: null,
    punteggi_problemi: [],
    classifica: [],
  };
  const timer = get_timer(inizio, fine, statoEvento)
  if (location.pathname.includes("telecronaca")) {
    return (
      <>
        <div className="flex-row">
          <TabellaPunteggiMinimal
            punteggi={ultimo.punteggi_problemi} nomi={nomi}
            dettagli_problemi={dettagli_problemi}
            classifica={ultimo.classifica}
            scuole_id={[]} squadre={squadre} sessione_pk={session_pk}
          />
          <RispostaFeed
            problemi={dettagli_problemi} squadre={squadre} timer={timer}
            session_pk={session_pk}
          />
        </div>
        <br />
        <br />
        <DisplayOrario />
      </>
    )
  }
  const [testo, blocco_fine] = get_testo_blocco(new Date(), inizio, fine)
  const error_toast = throttleError ? <ThrottleToast obj={this} /> : null;

  var nomi: { [squadra: string]: string; } = {};
  for (var sq of squadre) {
    nomi[sq.nome] = sq.ospite ? sq.nome + ' (Ospite)' : sq.nome;
  }

  const semp = semplificato(ultimo.classifica.length);
  let scuole_id = login.logged_in ? login.user.responsabile.scuole : []
  if (params.scuola_id) { scuole_id.push(parseInt(params.scuola_id)) }
  const classifica_semplificata = classifica_semp(ultimo.classifica, scuole_id, squadre);

  let blocco_slider = getBloccoSlider(location.pathname, session_pk, evento);

  return (
    <div className="container-fluid">
      {error_toast}
      <div style={{ paddingBottom: "30px", display: "flex" }}>
        <h1 className="page-header" style={{ marginTop: "10px", width: "33%" }}>{evento?.titolo} - sessione {sessione?.nome}</h1>
        <div style={{ width: "66%", marginTop: "24px" }}>
          {ultimo.ora &&
            <p style={{ textAlign: "right", marginBottom: "0" }}>Ultimo aggiornamento: {nice_date(ultimo.ora)}</p>
          }
          <p style={{ textAlign: "right", marginBottom: "0" }}>
            Questa pagina si ricarica da sola. Il refresh manuale è consigliato solo in caso di problemi di visualizzazione.
          </p>
          <p style={{ textAlign: "right", marginBottom: "0" }}>{testo}</p>
        </div>
      </div>
      {timer}
      {blocco_fine}
      {blocco_slider}
      <Tabs
        activeKey={tabKey}
        onSelect={(k) => {
          navigate(`${location.pathname}${location.search}#${k}`)
          setTabKey(k)
        }}
        id="nav-tab"
        mountOnEnter={true}
        className="mb-3"
      >
        <Tab eventKey="tabella-semplificata" title="Classifica semplificata">
          <TabellaPunteggi
            punteggi={ultimo.punteggi_problemi}
            nomi={nomi}
            dettagli_problemi={dettagli_problemi}
            classifica={classifica_semplificata}
            scuole_id={scuole_id}
            squadre={squadre}
          />
        </Tab>
        <Tab eventKey="tabella" title="Classifica">
          <TabellaPunteggi
            punteggi={ultimo.punteggi_problemi}
            nomi={nomi}
            dettagli_problemi={dettagli_problemi}
            classifica={ultimo.classifica}
            scuole_id={scuole_id}
            squadre={squadre}
          />
        </Tab>
        {!semp &&
          <Tab eventKey="plot" title="Grafico">
            <GraficoPunteggi pk={session_pk} nomi={nomi} evento={evento} />
          </Tab>
        }
        <Tab eventKey="bar" title="Grafico a barre">
          <IstogrammaPunteggi pk={session_pk} nomi={nomi} evento={evento} />
        </Tab>
        <Tab eventKey="bar2" title="Istogramma punteggi">
          <IstogrammaPunteggi2 pk={session_pk} evento={evento} />
        </Tab>
        <Tab eventKey="map" title="Mappa">
          <MappaLeaflet
            punteggi={ultimo.classifica}
            squadre={squadre}
            nomi={nomi}
          />
        </Tab>
        <Tab eventKey="messages" title="Messaggi importanti">
          {evento && <MessaggiImportantiList evento={evento} />}
        </Tab>
      </Tabs>
    </div>
  );
}

export default Punteggi;
